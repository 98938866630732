<template>
    <v-card flat>
        <v-card-title>
            <div class="d-flex align-center justify-space-between secondary--text w-100">
                <div class="d-flex flex-column">
                    <span class="font-weight-bold">System Administrator</span>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <v-tabs color="secondary" class="mt-5" v-model="tab">
                <v-tab v-for="tab in tabs" :key="tab.tabName">
                    <v-icon class="mr-1" small>{{ tab.icon }}</v-icon>
                    {{ tab.tabName }}
                </v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <v-tabs-items class="mt-7" v-model="tab">
                <v-tab-item v-for="tab in tabs" :key="tab.tabName">
                    <component :is="tab.component"></component>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>

<script>
const TheAdminCloneSpace = () => import('../components/TheAdminCloneSpace')

export default {
    components: { TheAdminCloneSpace },
    data() {
        return {
            tab: null,
            tabs: [{ tabName: 'Clone space', icon: 'dashboard', component: 'TheAdminCloneSpace' }]
        }
    }
}
</script>
